<!-- Start Our Works Area -->
<section id="works" class="our-works ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 offset-lg-2 text-center">
                <div class="section-title">
                    <h2>Designs Dee Portfolio</h2>
                    <p>Our portfolio is not just a series of media executions combining catchy phrases and attractive
                        images. Instead, we have focused on a highly strategic approach combining a comprehensive
                        understanding of design aesthetics, marketing, customer motivation and behaviour, as well as
                        constant monitoring and analysis to achieve highly effective solutions </p>
                    <p>
                        This has been made possible by our Creative Team which comprises individuals who are experts in
                        Design Creation, Animation, Video Production, Social Media Marketing, Web Design and Public
                        Relations. We're dedicated to providing you with the very best of creativity and effective
                        service in a timely and efficient manner. References from our satisfied clients have been
                        attached on this website.
                    </p>

                    <span class="section-title-bg">Portfolio</span>
                </div>
            </div>
        </div>

        <div id="filters" class="filter-menu text-center roxy-projects-active">
            <ul>
                <!-- <li class="active" data-filter="*" (click)="cardsFilter('')">All</li> -->

                <li class="" data-filter=".branding">Branding & Promotions</li>
                <li class="" data-filter=".digital">Digital Marketing</li>
                <li class="" data-filter=".tv">TV Ads</li>
                <li class="" data-filter=".animation">Video Creations</li>
                <li class="" data-filter=".news-letter">E-News Letters</li>
                <!-- <li class="" data-filter=".creative">Creative Designs</li> -->
                <li class="" data-filter=".logo">Logo</li>

                <!-- <li class="" data-filter=".mobile">Mobile</li>
                <li class="" data-filter=".ec">E-commerce</li> -->
                <!-- <li class="" data-filter=".python">Python</li>
                <li class="" data-filter=".django">Django</li> -->





                <!-- <li class="active" data-filter="*">All</li> -->
                <li class="active" data-filter="*">All</li>


                <!-- <li class=""  (click)="cardsFilter('image')">Django</li>
                <li class=""  (click)="cardsFilter('page')">Page</li> -->

            </ul>
        </div>


        <div id="Portfolio" class="row">
            <div class="col-sm-6 col-lg-4 filtr-item web" [ngClass]="image.class"
                *ngFor="let image of album; let i=index">

                <div class="work-details" (click)="open(i)" *ngIf="image.type == 'image'">
                    <img [src]="image.thumb" [alt]="image.caption">
                </div>

                <div class="work-details" *ngIf="image.type == 'page'">
                    <img [src]="image.thumb" [alt]="image.caption">
                    <div class="box-content" *ngIf="image.type == 'page'">
                        <h3 class="title">Campaign Details</h3>
                        <ul class="icon">
                            <li><a [href]="image.link"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>


                <div class="work-details popup-youtube " [attr.href]="image.link" *ngIf="image.type == 'video' || image.type == 'pdf'">
                    <!-- <img [src]="image.thumb" [alt]="image.caption"> -->


                    <div class="videos" *ngIf="image.type == 'video'">
                        <a href="#" class="video">
                            <span></span>
                            <img [src]="image.thumb" [alt]="image.caption">
                        </a>
                    </div>

                    <div class="pdfs" *ngIf="image.type == 'pdf'">
                        <a href="#" class="pdf">
                            <span></span>
                            <img [src]="image.thumb" [alt]="image.caption">
                        </a>
                    </div>

                </div>


            </div>

            <!-- <div class="col-sm-6 col-lg-4 filtr-item mobile django python">
                <div class="work-details">
                    <img src="assets/img/work-2.jpg" alt="sample image">
                    <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 filtr-item ec web">
                <div class="work-details">
                    <img src="assets/img/work-3.jpg" alt="sample image">
                    <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-sm-6 col-lg-4 filtr-item python mobile">
                <div class="work-details">
                    <img src="assets/img/work-4.jpg" alt="sample image">
                    <div class="box-content">
                        <h3 class="title">Work Details</h3>
                        <ul class="icon">
                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div> -->


            <!-- <div class="col-sm-6 col-lg-4 filtr-item django ec">
                <div class="work-details">
                    <img src="assets/img/work/main-page/thumbs/work-5.jpg" alt="sample image">
                    <div class="box-content">
                        <h3 class="title">Campaign Details</h3>
                        <ul class="icon">
                            <li><a href="/toyoto-lanka"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 filtr-item web python">
                <div class="work-details">
                    <img src="assets/img/work/main-page/thumbs/work-6.jpg" alt="sample image">
                    <div class="box-content">
                        <h3 class="title">Campaign Details</h3>
                        <ul class="icon">
                            <li><a href="/seylan-bank"><i class="fa fa-link"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div> -->

        </div>



    </div>
</section>
<!-- End Our Works Area -->


<!-- <router-outlet></router-outlet> -->